import "./fouc.js";
import "./navigation.js";
import "./skip-link-focus-fix.js";
import "./script.js";

import "./search.js";

import "../css/tailwind.css";

import "../sass/style.sass";

import { createApp } from "vue";
import App from "../vue/App.vue";
import router from "../vue/router/index.js";

import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/splide.min.css";
import VueSplide from "@splidejs/vue-splide";

import VueConfetti from "vue-confetti";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
// import {
//   faTimes,
//   faHandPointUp,
//   faTwitterSquare,
//   faFacebookSquare,
//   faYoutubeSquare,
//   faInstagramSquare,
// } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

document.addEventListener("DOMContentLoaded", function () {
  const el = document.querySelector("#vue-root");
  if (el) {
    // FontAwesome-Icons hinzufügen
    // library.add(
    //     faTimes,
    //     faTwitterSquare,
    //     faFacebookSquare,
    //     faYoutubeSquare,
    //     faInstagramSquare,
    //     faHandPointUp
    // );

    const app = createApp(App);

    // Komponenten und Plugins registrieren
    app.component("font-awesome-icon", FontAwesomeIcon);
    app.component("pulse-loader", PulseLoader);
    app.use(VueSplide);
    app.use(VueConfetti);
    app.use(router);

    // App einhängen
    app.mount("#vue-root");
  } else {
    console.log("#vue-root wurde nicht gefunden!");
  }
});

// document.addEventListener("DOMContentLoaded", function() {
//   const el = document.querySelector("#vue-root");
//   if (el) {
//     el.innerHTML = "<h1>Das DOM ist bereit und das Skript wird ausgeführt!</h1>";
//   } else {
//     console.error("#vue-root wurde nicht gefunden!");
//   }
// });
