jQuery(document).ready(function ($) {
  const $searchTrigger = $("li.search-trigger");
  const $searchBarContainer = $(".searchbar-container");
  const $menuSearchItem = $(".menu-item.search-trigger");
  const $menuCloseToggle = $(".mobile-menu-close-icon");

  $("li.search-trigger").hover(function () {
    let icon = $(this).find(".search-icon");
    let tempSrc = icon.attr("src");
    icon.attr("src", icon.data("hover-src"));
    icon.data("hover-src", tempSrc);
  });

  $menuCloseToggle.on("click touch", function () {
    $searchBarContainer.css("max-height", "0px");
    $searchBarContainer.css("padding-top", "0");
    $searchBarContainer.css("padding-bottom", "0");

    // Farbänderung rückgängig machen
    $menuSearchItem
      .removeClass("search-active")
      .addClass("bg-red")
      .removeClass("bg-blue");
  });

  $searchTrigger.on("click", function (e) {
    e.preventDefault();

    if ($searchBarContainer.height() && $searchBarContainer.height() !== 0) {
      // Container verbergen
      $searchBarContainer.css("max-height", "0px");
      $searchBarContainer.css("padding-top", "0");
      $searchBarContainer.css("padding-bottom", "0");

      // Farbänderung rückgängig machen
      $menuSearchItem
        .removeClass("search-active")
        .addClass("bg-red")
        .removeClass("bg-blue");
    } else {
      // Container anzeigen
      $searchBarContainer.css("max-height", "500px");
      $searchBarContainer.css("padding-top", "4em");
      $searchBarContainer.css("padding-bottom", "4em");

      // Farbänderung hinzufügen
      $menuSearchItem
        .addClass("search-active")
        .removeClass("bg-red")
        .addClass("bg-blue");
    }
  });
});
