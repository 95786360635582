import { createRouter, createWebHashHistory } from 'vue-router';
import Diashows from '../components/Diashows.vue';
import Slider from '../components/Slider.vue';
import Quiz from '../components/Quiz.vue';

const routes = [
  {
    path: '/slider',
    name: 'Slider',
    component: Slider,
  },
  {
    path: '/',
    name: 'Diashows',
    component: Diashows,
  },
  {
    path: '/quiz/:id',
    name: 'Quiz',
    component: Quiz,
    props: true,
  },
];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log('Navigating to:', to);
  next();
});

export default router;
